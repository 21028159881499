import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import ArticleImage from '../components/ArticleImage'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export default class BlogPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    

    return (
      <Layout>
        <section className="section is-blog">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10-fullhd is-12">
              <div className="section-header">
                <div className="header is-spaced">
                  <h2 className="title is-4">Updates</h2>
                </div>
              </div>
              <div className="section-body">
                <div className="articles">
                  <div className="columns is-multiline is-variable is-5">
                  {posts.map(({ node: post }) => (

                    <div className="column is-3-widescreen is-4-tablet" key={post.id}>
                    <p className="article-meta"> <span>{post.frontmatter.date}</span> </p>
                      <div className="article-item is-spaced">  <Link className="article-cover" to={post.fields.slug}>
                         
                          </Link>
                          {
                    
                  }
                        <div className="article-content">
                          <h3> <Link className="article-title" to={post.fields.slug}>{post.frontmatter.title}</Link>
                            </h3>
                          <p className="article-meta"> <span>by {post.frontmatter.author}</span> </p>
                          
                        </div>
                      </div>
                    </div>
                     ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
       
      </Layout>
    )
  }
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            author
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
