import React from 'react'
import PropTypes from 'prop-types'


const ArticleImage = ({ imageurl}) => (
  <div className="article-image"  style={{
    backgroundImage: 'url(' + imageurl + ')',
    backgroundSize: 100,
  }}> </div>
)

ArticleImage.propTypes = {
  imageurl: PropTypes.object,

}

ArticleImage.propTypes = ArticleImage.propTypes

export default ArticleImage